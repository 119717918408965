<template>
  <div id="app">
    <VueHeader />
    <router-view class="main" />
    <VueFooter />
  </div>
</template>

<script>
import VueHeader from "./components/VueHeader.vue";
import VueFooter from "@/components/VueFooter.vue";
export default {
  components: {
    VueHeader,
    VueFooter,
  },
};
</script>
<style lang="scss">
html {
  scroll-behavior: smooth;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.main {
  flex: 1;
}
</style>
