<template>
  <footer class="footer py-4">
    <div class="container">
      <a
        href="https://www.facebook.com/groups/141297053287010/"
        target="_blank"
      >
        <img src="../assets/img/icoFace.png" class="logo"
      /></a>
      <span>Incursores.com.br - {{ year }}</span>
    </div>
  </footer>
</template>
<script>
export default {
  name: "VueFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss">
.footer {
  background: #212529;
  color: white;
  text-align: center;
  .logo {
    width: 40px;
    margin: auto;
    display: block;
  }
  span {
    display: block;
    margin-top: 10px;
    border-top: 1px solid #444444;
    padding-top: 10px;
  }
}
</style>
