<template>
  <section class="infobar py-3">
    <div class="container">
      <p class="text-center mb-0" v-if="!api">Carregando informação...</p>
      <div class="row" v-else>
        <div class="col-lg-3 col-6 mb-3 mb-lg-0 text-center item">
          <div class="wrapper">
            <i class="bi bi-people-fill"></i>
            <p class="mb-0">Usuários Registrados</p>
            <p class="mb-0 font-weight-bold">{{ api.usersRegistered }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 mb-3 mb-lg-0 text-center item">
          <div class="wrapper">
            <i class="bi bi-controller"></i>
            <p class="mb-0">Usuários Online</p>
            <p class="mb-0 font-weight-bold">{{ api.usersActives }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 mb-3 mb-lg-0 text-center item">
          <div class="wrapper">
            <i class="bi bi-list-ol"></i>
            <p class="mb-0">Grades Abertas</p>
            <p class="mb-0 font-weight-bold">{{ api.gridsNow }}</p>
          </div>
        </div>
        <div class="col-lg-3 col-6 mb-3 mb-lg-0 text-center item">
          <div class="wrapper">
            <i class="bi bi-bar-chart-fill"></i>
            <p class="mb-0">Grades na Semana</p>
            <p class="mb-0 font-weight-bold">{{ api.gridsWeek }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "VueInfoBar",
  props: {
    api: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.infobar {
  background: #212529;
  color: white;

  h3 {
    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 70px;
      margin: auto;
      margin-top: 15px;
      background: white !important;
    }
  }
  .item {
    i {
      font-size: 2rem;
    }
  }
}
</style>
